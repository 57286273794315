<template>
  <div>
    <Breadcrumbs
      :items="breadcrumbs"
      page_title="selecteduser"
      :item_no="item_No"
    />
    <v-container fluid>
      <v-card class="mx-3 my-5" style="border-radius: 16px">
        <v-container>
          <v-row justify="space-between" class="ma-1">
            <v-col cols="12" lg="3">
              <v-text-field
                outlined
                dense
                hide-details
                style="border-radius: 8px; border-color: #c0c0c0"
                clearable
                prepend-inner-icon="mdi-magnify"
                :placeholder="$t('search')"
                v-model="search"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="7" style="text-align: right !important">
              <v-btn
                class="text-capitalize"
                style="
                  color: #424242;
                  font-size: 16px;
                  border: 1px solid #c0c0c0;
                  box-shadow: unset !important;
                  border-radius: 8px;
                  background: #ffffff 0% 0% no-repeat padding-box;
                "
                @click="showFilterTools = !showFilterTools"
              >
                <v-icon class="mr-2" :small="true">mdi-tune-variant</v-icon>
                {{ $t("filter") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="showFilterTools" class="ma-1">
            <v-col cols="12" lg="3">
              <label
                for="group"
                style="
                  font-size: 16px;
                  color: #424242;
                  font-weight: bold;
                  margin-top: 5px;
                "
                >{{ $t("group") }}</label
              >
              <v-select
                outlined
                class="rounded-lg"
                v-model="dept"
                :items="Departmentitems"
                hide-no-data
                hide-details
                item-text="groupName"
                placeholder="-- Please select --"
                item-value="groupID"
                solo
                @change="(event) => SelectDept(event)"
                @blur="clearSearchWord()"
                dense
                clearable
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchgroup"
                        :placeholder="$t('search')"
                        @input="searchInLists()"
                        autocomplete="off"
                        hide-details
                        class="searchinlist"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item ripple @click="getAllDept">
                    <v-list-item-action>
                      <v-icon :color="dept.length > 0 ? '#a6cc39' : ''">{{
                        Depticon
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.groupName }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption"
                    >(+{{ dept.length - 1 }} others)</span
                  >
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row class="ma-1">
            <v-col>
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                v-model="selected"
                show-select
                :headers="headers"
                @toggle-select-all="getSelectAll"
                :items="items"
                item-key="userID"
                :search="search"
                class="department-table"
                hide-default-footer
                @page-count="pageCount = $event"
                :page.sync="page"
                :items-per-page="itemsPerPage"
              >
                <template v-slot:top></template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row justify="space-between" class="mx-6 my-4">
            <v-row>
              <v-col cols="auto" class="mr-auto">
                <v-pagination
                  circle
                  v-model="page"
                  class="pagination"
                  :length="pageCount"
                ></v-pagination>
              </v-col>

              <v-col cols="12" lg="2" md="3" sm="4" class="d-flex justify-end">
                <p class="mt-3 pr-4">{{ $t("Show") }}</p>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      width="70"
                      v-bind="attrs"
                      v-on="on"
                      style="border: 1px solid #c0c0c0; opacity: 1"
                      class="text-capitalize mt-1"
                      outlined
                    >
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in paginationCount"
                      :key="index"
                      @click="itemsPerPage = item.title"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-row>

          <v-row justify="end" class="ma-2">
            <v-btn
              text
              outlined
              class="text-capitalize mr-3"
              style="font-size: 16px"
              @click="
                $store.commit('changeLibraryAction', false),
                  $router.push('/home/assignment-course'),
                  $store.commit(
                    editassignment
                      ? 'openAssignmentEditDialog'
                      : 'openAssignmentDialog'
                  )
              "
              >{{ $t("cancel") }}</v-btn
            >

            <v-btn
              color="secondary"
              width="100px"
              class="text-capitalize white--text btn_hover_effect"
              style="font-size: 16px; border-radius: 8px"
              @click="
                $store.commit('saveSelecteduserList', selected),
                  $store.commit('changeLibraryAction', false),
                  $router.push('/home/assignment-course')
              "
              >{{ $t("save") }}</v-btn
            >
            <!-- $store.commit(
              editassignment ? 'openAssignmentEditDialog' : 'openAssignmentDialog'
            )-->
          </v-row>
        </v-container>
      </v-card>
    </v-container>
    <DeleteConfirmDialog
      :show="confirmMessage.show"
      @close="confirmMessage.show = false"
      @confirm="deleteConfirm()"
    />

    <SuccessDialog
      :show="showMessage.show"
      :title="showMessage.title"
      :text="showMessage.text"
      @close="showMessage.show = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    item_No: 0,
    showFilterTools: false,
    loading: false,
    itemsCopy: [],
    Departmentitems: [],
    groupCopy: [],
    searchgroup: null,
    dept: [],
    editDialog: false,
    search: null,
    items: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    paginationCount: [
      {
        title: 5,
      },
      {
        title: 10,
      },
      {
        title: 20,
      },
      {
        title: 30,
      },
      {
        title: 40,
      },
    ],
    breadcrumbs: [
      {
        //image: "house.png"
        sidebar_tilte: "home",
      },
      {
        text: "course", //assignmentcourse
        href: "/home/assignment-course",
      },
      {
        text: "selecteduser", //assignmentcourse
      },
    ],
    selected: [],
    showMessage: {
      show: false,
      title: "Success",
      text: "Deleting User is successful.",
    },
    confirmMessage: {
      show: false,
    },
  }),
  computed: {
    ...mapGetters(["selecteduserList", "editassignment"]),
    headers() {
      return [
        {
          text: this.$t("employeeId"),
          align: "left",
          value: "profileID",
          class: "tableColor",
        },
        {
          text: this.$t("fullnamesm"),
          align: "left",
          value: "fullName",
          class: "tableColor",
        },
        {
          text: this.$t("email"),
          align: "left",
          value: "email",
          class: "tableColor",
        },
        {
          text: this.$t("ph"),
          align: "left",
          value: "mobile",
          class: "tableColor",
        },
        {
          text: this.$t("group"),
          align: "left",
          value: "department",
          class: "tableColor",
        },
      ];
    },
    selectAllDept() {
      return this.dept.length === this.Departmentitems.length;
    },
    Depticon() {
      if (this.selectAllDept) return "mdi-checkbox-marked";
      return "mdi-checkbox-blank-outline";
    },
    // message: {
    //   get() {
    //     return (
    //       'Are you sure you want to delete "user ID ' +
    //       this.userEditData.empID +
    //       " ?"
    //     );
    //   },
    // },
  },
  mounted() {
    this.getUserData();
    this.getSelected();
    this.getDepartment();
  },
  methods: {
    clearSearchWord() {
      this.searchgroup = null;
      this.searchInLists();
    },

    searchInLists() {
      if (!this.searchgroup) {
        this.Departmentitems = this.groupCopy;
      }

      this.Departmentitems =
        this.searchgroup != null
          ? this.groupCopy.filter((x) => {
              return (
                x.groupName
                  .toLowerCase()
                  .indexOf(this.searchgroup.toLowerCase()) > -1
              );
            })
          : this.groupCopy;
    },

    SelectDept(event) {
      let self = this;
      let matchedItems = [];

      if (event.length === 0) {        
        self.items = self.itemsCopy;
      } else {       
        self.items = self.itemsCopy;

        let allDeptEmpIds = [];        
        for (let i of event) {
          self.Departmentitems.forEach((item) => {
            if (item.groupEmployee) {
              item.groupEmployee.forEach((groupEmp) => {
                if (groupEmp.groupID === i && groupEmp.employeeID) {
                  allDeptEmpIds.push(groupEmp.employeeID);
                }
              });
            }
          });
        }

        if (allDeptEmpIds.length > 0) {         
          matchedItems = self.items.filter((item) =>
            allDeptEmpIds.includes(item.userID)
          );

          self.items = matchedItems;
        }
      }
    },

    getAllDept() {
      if (this.dept.length == 0) {
        this.dept = this.Departmentitems.map(({ groupID }) => groupID);
      } else {
        this.dept = [];
        this.items = this.itemsCopy; //in case there is no gp name data included in table...
      }
    },

    async getDepartment() {
      let self = this;
      const res = await self.$axios.get(
        `${self.web_url}Group/GetGroupALL?id=` +
          localStorage.getItem("companyID")
      );
      if (res.data.status == 0) {
        let temp = res.data.data;
        self.Departmentitems = temp.sort((a, b) =>
          a.groupName.toLowerCase() > b.groupName.toLowerCase() ? 1 : -1
        );
        self.groupCopy = self.Departmentitems;
      }
    },

    getSelectAll() {
      if (this.selected == undefined) {
        this.selected = this.items;
      } else if (this.selected.length == 0) {
        this.selected = this.items;
      } else {
        this.selected = [];
      }
    },
    async getUserData() {
      this.loading = true;
      const res = await this.$axios.get(
        `${this.web_urlV6}EmployeeEXT/GetEmployeeExtAllV4?id=` +
          localStorage.getItem("companyID")
      );
      this.items = res.data.data.map((v, i) => ({
        ...v,
        no: i + 1,
      }));
      this.item_No = this.items.length;
      this.itemsCopy = this.items;
      this.loading = false;
    },

    getSelected() {
      this.selected = this.selecteduserList;
    },

    async deleteConfirm() {
      const res = await this.$axios.post(
        `${this.web_url}EmployeeEXT/DeleteEmployeeExt?id=` +
          this.userEditData.userID
      );
      if (res) {
        this.confirmMessage.show = false;
        this.showMessage.show = true;
        this.getUserData();
      }
    },
  },
};
</script>

<style scoped>
/* >>> .department-table tr:nth-child(even) {
  background: #e0e0e0;
} */
::v-deep .v-input--selection-controls__input .v-icon {
  /* color: #a6cc39 !important; */
  border-radius: 8px;
  /* opacity: 1; */
}
::v-deep .v-text-field__slot {
  font-size: 16px !important;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #dbdbda !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
}
::v-deep .pagination .primary {
  background-color: #a6cc39 !important;
  color: #ffffff !important;
  /* opacity: 1; */
}

/*for group filter */
::v-deep .pre-fix .v-input__slot {
  border: 1px solid #707070;
  width: 200px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  /* margin-left: 15px; */
  /* margin-top: 20px; */
  align-content: end;
}
.searchinlist.v-text-field {
  margin-left: 5px;
  padding-top: 0px;
  margin-top: 0px;
}
/* >>> .v-input__control {
  align-content: end;
} */
</style>

<style>
.v-pagination__navigation {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid #c0c0c0;
}
.v-pagination__item {
  background: transparent !important;
  box-shadow: none !important;
}
.department-table thead th:first-child {
  border-radius: 8px 0 0 8px !important;
}
.department-table thead th:last-child {
  border-radius: 0 8px 8px 0 !important;
}
</style>

<style scoped>
::v-deep .v-simple-checkbox .mdi-checkbox-blank-outline {
  color: #c0c0c0 !important;
}
::v-deep .v-simple-checkbox .mdi-checkbox-marked {
  color: #a6cc39 !important;
}
::v-deep .v-simple-checkbox .mdi-minus-box {
  color: #a6cc39 !important;
}
@media (min-width: 1264px) {
  .container {
    max-width: unset;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: unset;
  }
}
::v-deep .accent {
  background-color: #a6cc39 !important;
}
::v-deep .accent--text {
  color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: unset !important;
}
.btn_hover_effect {
  width: 50%;
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.reset-btn {
  width: 13%;
  color: #fff;
  font-size: 11px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
</style>
